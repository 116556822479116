<template>
    <v-data-table
      :headers="headers"
      :items="loading ? [] : items"
      :items-per-page="itemsPerPage"
      :hide-default-footer="hideDefaultFooter"
      :sort-by="sortByHeaders"
      :sort-desc="sortByDesc"
      class="elevation-0 reports__table"
      :loading="loading"
      height="100%"
    >
        <template v-slot:item.evaluationType="{ item }" >
            <span>
                {{ item.levelName }}
            </span>
        </template>

        <template v-slot:item.section="{ item }" >
            <v-chip label color="deep-purple lighten-5" class="px-9 my-3">
                <v-row align-content="center" justify="start">
                    <v-icon color="deep-purple" class="mr-2" small>
                        mdi-folder
                    </v-icon>
                    <span class="body-2 deep-purple--text lighten-5 text-capitalize font-weight-medium">
                        {{ item.section }}
                    </span>
                </v-row>
            </v-chip>
        </template>

        <template v-slot:item.evaluationProgress="{ item }" >
            <span v-if="item.evaluationProgress != null"  class="d-flex flex-row align-center justify-center" data-cy="table-bookProgress-span">
                {{ item.evaluationProgress ? Number(item.evaluationProgress).toFixed(0) : 0 }}% <v-progress-linear class="mx-3" :value="item.evaluationProgress ? item.evaluationProgress : 0" color="primary" rounded height="10px"></v-progress-linear>
            </span>
            <span v-else class="text-center"> - </span>
        </template>

        <template v-slot:item.course="{ item }" >
            <v-chip label color="deep-purple lighten-5" class="px-9 my-3">
                <v-row align-content="center" justify="start">
                    <v-icon color="deep-purple" class="mr-2" small>
                        mdi-folder
                    </v-icon>
                    <span class="body-2 deep-purple--text lighten-5 text-capitalize font-weight-medium">
                        {{ item.course.name }}
                    </span>
                </v-row>
            </v-chip>
        </template>

        <template v-slot:item.startDate="{ item }" >
            <v-chip
                v-if="item.assignmentType === 'ASSIGNED'"
                label
                color="deep-purple lighten-5"
                class="px-9 my-3"
            >
                <v-row align-content="center" justify="start">
                    <v-icon color="deep-purple" class="mr-2" small>
                        mdi-calendar
                    </v-icon>
                    <span class="body-2 deep-purple--text lighten-5 text-capitalize font-weight-medium">
                        {{ item?.dateStart?.substring(0, 10) }}
                    </span>
                </v-row>
            </v-chip>
            <span v-else class="body-2 deep-purple--text lighten-5 text-capitalize font-weight-medium">
                -
            </span>
        </template>

        <template v-slot:item.assignmentType="{ item }">
            <v-chip
                label
                color="deep-purple lighten-5"
                class="px-9 my-3"
            >
                <v-row align-content="center" justify="start">
                    <span class="body-2 deep-purple--text lighten-5 text-capitalize font-weight-medium">
                        {{ getAssignmentTypeName(item?.assignmentType) }}
                    </span>
                </v-row>
            </v-chip>
        </template>

        <!-- Actions -->
        <template v-slot:item.id="{ item }">
                <v-row class="flex-nowrap" align-content="center" justify="center">
                    <!-- Stats -->
                    <v-btn
                        data-cy="stats-evaluation-btn"
                        v-if="showDetail"
                        icon
                        elevation="0"
                        class="primary ma-1 pa-3"
                        small
                        @click="goToStats(item)"
                    >
                        <v-icon small class="pa-3" color="white">mdi-poll</v-icon>
                    </v-btn>

                    <!-- Edit -->
                    <v-btn
                        v-if="item.assignmentType === 'ASSIGNED'"
                        data-cy="edit-evaluation-btn"
                        icon
                        elevation="0"
                        class="deep-purple lighten-5 ma-1 pa-3"
                        small
                        @click="goToModify(item)"
                    >
                        <v-icon small class="pa-3" color="primary">mdi-pencil</v-icon>
                    </v-btn>

                    <!-- Delete -->
                    <v-btn
                        icon
                        elevation="0"
                        class="red lighten-5 ma-1 pa-3"
                        small
                        @click="deleteBookAssigment(item)"
                    >
                        <v-icon data-cy="delete-evaluation-btn" small class="pa-3" color="red">mdi-trash-can-outline</v-icon>
                    </v-btn>
                </v-row>
            </template>
    </v-data-table>
</template>

<script>
export default {
    name: 'DiagnosticsTable',
    props: {
        loading: {
            type: Boolean,
            default: false,
            required: false,
        },
        items: {
            type: Array,
            default: () => [],
            required: false,
        },
        itemsPerPage: {
            type: Number,
            default: 10,
            required: false,
        },
        hideDefaultFooter: {
            type: Boolean,
            default: true,
            required: false,
        },
        sortByHeaders: {
            type: Array,
            default: () => [],
            required: false,
        },
        sortByDesc: {
            type: Boolean,
            default: false,
            required: false,
        },
        headers: {
            type: Array,
            default: () => [],
            required: false,
        },
        showDetail: {
            type: Boolean,
            default: false,
            required: false,
        }
    },
    methods: {
        goToStats(evaluation) {
            this.$emit('on-stats', evaluation)
        },
        goToModify(evaluation) {
            this.$emit('on-update', evaluation)
        },
        deleteBookAssigment(evaluation) {
            this.$modal.confirm(
                this.$t('library.confirm_delete'),
                this.$t('diagnoses.actions.confirmation.delete'),
                async () => {
                    this.$emit('on-delete', evaluation)
                },
            )
        },
        getAssignmentTypeName(assignmentType) {
            return this.$t(`diagnoses.diagnosis.assignmentType.${assignmentType}`)
        },
    }

}
</script>

<style scope lang="scss">
@import '../../../../../assets/style/colors.scss';

.headers-table {
  background: $purple-primary !important;
}

.reports__table .v-data-table-header {
  background-color: $purple-primary;
}
.reports__table .v-data-table-header .sortable.active {
  background-color: #D7D2EF;
}
.v-data-table__wrapper > table > thead > tr:last-child > th {
  border-bottom: none !important;
}
.evaluation-option {
  cursor: pointer;
}

.text-no-wrap {
  word-break: keep-all;
}

</style>
