<template>
    <v-data-table
        :headers="sortableHeaders"
        :items="loading ? [] : items"
        :items-per-page="itemsPerPage"
        :hide-default-footer="hideDefaultFooter"
        :sort-by="sortByHeaders"
        :sort-desc="sortByDesc"
        class="elevation-0 reports__table"
        :loading="loading"
        height="100%"
    >
        <template v-slot:item.name="{ item }">
            <span>
                {{ item.name }}
            </span>
        </template>

        <template v-slot:item.diagnosticState="{ item }">

            <v-tooltip right v-if="item.diagnosticState === 'COMPLETED'" color="white">
                <template v-slot:activator="{ on, attrs }">
                    <v-chip v-bind="attrs" v-on="on" pill color="green darken-1">
                        <v-icon color="white" small>
                            mdi-clipboard-check
                        </v-icon>
                    </v-chip>
                </template>
                <span class="black--text">{{ $t("diagnoses.texts.tooltips.diagnosesState.completed") }}</span>
            </v-tooltip>

            <v-tooltip right v-if="item.diagnosticState === 'ASSIGNED'" color="white">
                <template v-slot:activator="{ on, attrs }">
                    <v-chip v-bind="attrs" v-on="on" pill  color="amber lighten-2">
                        <v-icon color="white" small>
                            mdi-clipboard-remove
                        </v-icon>
                    </v-chip>
                </template>
                <span class="black--text">{{ $t("diagnoses.texts.tooltips.diagnosesState.inProgress") }}</span>
            </v-tooltip>
        </template>

        <template v-slot:item.studentTier="{ item }">
            <v-chip
                v-if="defineChipTierColor(item.studentTier)"
                :color="defineChipTierColor(item.studentTier)"
                text-color="white"
                class="font-weight-medium body-2"
                small
                label
                data-cy="table-tier-chip"
            >
                {{ $t('reports.utils.level') }} {{ getOrdinalTier(item.studentTier) }}
            </v-chip>
            <span v-else class="text-center"> - </span>
        </template>

        <!-- Beereaders Stage -->
        <template v-slot:item.beereadersStage="{ item }">
            <span v-if="item.beereadersStage">
                {{ item.beereadersStage }}
            </span>
            <span v-else> - </span>
        </template>

        <!-- Actions -->
        <template v-slot:item.id="{ item }">
            <v-row class="flex-nowrap" align-content="center" justify="center">
                <!-- Delete -->
                <v-btn
                    v-if="item.diagnosticState !== 'NOT_STARTED'"
                    icon
                    elevation="0"
                    class="red lighten-5 ma-1 pa-3"
                    small
                    @click="deleteEvaluationResult(item.id)"

                >
                    <v-icon small class="pa-3" color="red">mdi-trash-can-outline</v-icon>
                </v-btn>
                <SimpleButton
                    v-else
                    icon="mdi-clipboard-check-multiple"
                    :text="$t('diagnoses.actions.assign')"
                    @click="assignEvaluation(item.id)"
                />
            </v-row>
        </template>

        <!-- ------------------------------- -->
        <!-- --- Performance Skills Data --- -->
        <!-- ------------------------------- -->
        <template v-slot:item.skills.locate.average="{ item }">
            <v-tooltip v-if="isEvaluationStateCompleted(item) && item.skills.locate != null" right color="grey lighten-5">
                <template v-slot:activator="{ on, attrs }">
                    <span
                        class="d-flex flex-row align-center justify-center"
                        v-bind="attrs"
                        v-on="on"
                        data-cy="table-locateSkill-span"
                    >
                        {{ item.skills.locate.average }}%
                        <v-progress-linear
                            v-if="isEvaluationStateCompleted(item)"
                            class="mx-3"
                            :value="item.skills.locate.average"
                            :color="getSkillColor('locate')"
                            rounded
                            height="10px"
                        ></v-progress-linear>
                    </span>
                </template>
                <p class="black--text font-weight-regular" data-cy="table-locateSkillMsg-p">
                    <v-badge inline dot left color="#828DF6"></v-badge>
                    {{ item.skills.locate.corrects }} {{ $t('reports.utils.of') }} {{ item.skills.locate.total }}
                    <span class="font-weight-bold">{{ item.skills.locate.average }}%</span>
                </p>
            </v-tooltip>
            <span v-else class="text-center" data-cy="table-locateSkillNull-span"> - </span>
        </template>

        <template v-slot:item.skills.integrate.average="{ item }">
            <v-tooltip v-if="isEvaluationStateCompleted(item) && item.skills.integrate != null" right color="grey lighten-5">
                <template v-slot:activator="{ on, attrs }">
                    <span
                        class="d-flex flex-row align-center justify-center"
                        v-bind="attrs"
                        v-on="on"
                        data-cy="table-integrateSkill-span"
                    >
                        {{ item.skills.integrate.average }}%
                        <v-progress-linear
                            v-if="isEvaluationStateCompleted(item)"
                            class="mx-3"
                            :value="item.skills.integrate.average"
                            :color="getSkillColor('integrate')"
                            rounded
                            height="10px"
                        ></v-progress-linear>
                    </span>
                </template>
                <p class="black--text font-weight-regular" data-cy="table-integrateSkillMsg-p">
                    <v-badge inline dot left color="#65C2FD"></v-badge>
                    {{ item.skills.integrate.corrects }} {{ $t('reports.utils.of') }}
                    {{ item.skills.integrate.total }}
                    <span class="font-weight-bold">{{ item.skills.integrate.average }}%</span>
                </p>
            </v-tooltip>
            <span v-else class="text-center" data-cy="table-integrateSkillNull-span"> - </span>
        </template>

        <template v-slot:item.skills.reflect.average="{ item }">
            <v-tooltip v-if="isEvaluationStateCompleted(item) && item.skills.reflect != null" right color="grey lighten-5">
                <template v-slot:activator="{ on, attrs }">
                    <span
                        class="d-flex flex-row align-center justify-center"
                        v-bind="attrs"
                        v-on="on"
                        data-cy="table-reflectSkill-span"
                    >
                        {{ item.skills.reflect.average }}%
                        <v-progress-linear
                            v-if="isEvaluationStateCompleted(item)"
                            class="mx-3"
                            :value="item.skills.reflect.average"
                            :color="getSkillColor('reflect')"
                            rounded
                            height="10px"
                        ></v-progress-linear>
                    </span>
                </template>
                <p class="black--text font-weight-regular" data-cy="table-reflecSkillMsg-p">
                    <v-badge inline dot left color="#6DCDDD"></v-badge>
                    {{ item.skills.reflect.corrects }} {{ $t('reports.utils.of') }}
                    {{ item.skills.reflect.total }}
                    <span class="font-weight-bold">{{ item.skills.reflect.average }}%</span>
                </p>
            </v-tooltip>
            <span v-else class="text-center" data-cy="table-reflectSkillNull-span"> - </span>
        </template>

        <template v-slot:item.skills.readingComprehension="{ item }" >
            <span
                v-if="isEvaluationStateCompleted(item) && item.skills.readingComprehension != null"
                class="d-flex flex-row align-center justify-center"
                data-cy="table-comprehension-span"
            >
                {{ item.skills.readingComprehension }}%
                <v-progress-linear
                    v-if="isEvaluationStateCompleted(item)"
                    class="mx-3"
                    :value="item.skills.readingComprehension"
                    :color="getSkillColor('readingComprehension')"
                    rounded
                    height="10px"
                ></v-progress-linear>
            </span>
            <span v-else class="text-center" data-cy="table-comprehensionNull-span"> - </span>
        </template>
    </v-data-table>
</template>

<script>
import SimpleButton from '@/components/core/buttons/simpleButton/index.vue'

export default {
    name: 'EvaluationsResultsTable',
    components: {
        SimpleButton,
    },
    data() {
        return {
        }
    },
    props: {
        loading: {
            type: Boolean,
            default: false,
            required: false,
        },
        items: {
            type: Array,
            default: () => [],
            required: false,
        },
        itemsPerPage: {
            type: Number,
            default: 10,
            required: false,
        },
        hideDefaultFooter: {
            type: Boolean,
            default: true,
            required: false,
        },
        sortByHeaders: {
            type: Array,
            default: () => [],
            required: false,
        },
        sortByDesc: {
            type: Boolean,
            default: false,
            required: false,
        },
        headers: {
            type: Array,
            default: () => [],
            required: false,
        },
        canEdit: {
            type: Boolean,
            default: false,
            required: false,
        },
    },
    computed: {
        sortableHeaders() {
            const baseHeaders = [
                {
                    text: this.$t('diagnoses.tables.studentsList.labels.student'),
                    align: 'start',
                    sortable: false,
                    value: 'name',
                },
                {
                    text: this.$t('diagnoses.tables.studentsList.labels.state'),
                    align: 'center',
                    sortable: true,
                    value: 'diagnosticState',
                },
                {
                    text: this.$t('diagnoses.utils.skills.locate'),
                    align: 'center',
                    sortable: true,
                    value: 'skills.locate.average',
                    customSort: (items, index, isDesc) => this.SortTypeSkillFunction(items, index, isDesc, 'locate'),
                },
                {
                    text: this.$t('diagnoses.utils.skills.integrate'),
                    align: 'center',
                    sortable: true,
                    value: 'skills.integrate.average',
                    customSort: (items, index, isDesc) => this.SortTypeSkillFunction(items, index, isDesc, 'integrate'),
                },
                {
                    text: this.$t('diagnoses.utils.skills.reflect'),
                    align: 'center',
                    sortable: true,
                    value: 'skills.reflect.average',
                    customSort: (items, index, isDesc) => this.SortTypeSkillFunction(items, index, isDesc, 'reflect'),
                },
                {
                    text: this.$t('diagnoses.utils.skills.readingComprenhension'),
                    align: 'center',
                    sortable: true,
                    value: 'skills.readingComprehension',
                },
                {
                    text: this.$t('diagnoses.tables.studentsList.labels.tier'),
                    align: 'center',
                    sortable: true,
                    value: 'studentTier',
                },
                {
                    text: this.$t('diagnoses.tables.studentsList.labels.beeStage'),
                    align: 'center',
                    sortable: true,
                    value: 'beereadersStage',
                },
            ]

            return baseHeaders
        },
    },
    methods: {
        getEvaluationProgress(progress) {
            switch (progress) {
                case 'NOT_STARTED':
                    return 'No ha empezado'
                case 'IN_PROGRESS':
                    return 'En progreso'
                case 'COMPLETED':
                    return 'Completado'
            }
        },
        defineChipTierColor(tierLevel) {
            switch (tierLevel) {
                case 'TIER_GROUP_1':
                    return 'green darken-2'
                case 'TIER_GROUP_2':
                    return 'amber accent-3'
                case 'TIER_GROUP_3':
                    return 'red darken-2'
                default:
                    return null
            }
        },
        getOrdinalTier(tierLevel) {
            switch (tierLevel) {
                case 'TIER_GROUP_1':
                    return 1
                case 'TIER_GROUP_2':
                    return 2
                case 'TIER_GROUP_3':
                    return 3
                default:
                    return '-'
            }
        },
        getSkillColor(skill) {
            switch (skill) {
                case 'locate':
                    return '#828DF6'
                case 'integrate':
                    return '#65C2FD'
                case 'reflect':
                    return '#6DCDDD'
                case 'readingComprehension':
                    return 'primary'
                default:
                    return 'primary'
            }
        },
        deleteEvaluationResult(studentID) {
            const vm = this
            this.$modal.confirm(
                this.$t('library.confirm_delete'),
                this.$t('library.confirm_delete_msg'),
                async () => {
                    this.$emit('on-delete', studentID)
                }
            )
        },
        assignEvaluation(studentID) {
            const vm = this
            this.$modal.confirm(
                this.$t('diagnoses.titles.assignEvaluation'),
                this.$t('diagnoses.actions.confirmation.assign'),
                async () => {
                    this.$emit('on-assign', studentID)
                }
            )
        },
        isEvaluationStateCompleted(evaluation) {
            return evaluation.diagnosticState === 'COMPLETED'
        },
        SortTypeSkillFunction(items, index, isDesc, skillType) {
            items.sort((a, b) => {
                const valueA = this.getSortingValue(a, skillType)
                const valueB = this.getSortingValue(b, skillType)

                return isDesc ? valueB - valueA : valueA - valueB
            });

            return items
        },
        getSortingValue(item, skillType) {
            // Obtener el valor específico para la ordenación de la celda
            switch (skillType) {
                case 'integrate':
                    return item.skills.integrate.average
                case 'locate':
                    return item.skills.locate.average
                case 'reflect':
                    return item.skills.reflect.average
                default:
                return 0
            }
        },
    },
}
</script>

<style scope lang="scss">
@import '../../../../../assets/style/colors.scss';

.headers-table {
    background: $purple-primary !important;
}

.reports__table .v-data-table-header {
    background-color: $purple-primary;
}
.reports__table .v-data-table-header .sortable.active {
    background-color: #d7d2ef;
}
.v-data-table__wrapper > table > thead > tr:last-child > th {
    border-bottom: none !important;
}
.evaluation-option {
    cursor: pointer;
}

.text-no-wrap {
    word-break: keep-all;
}
.v-data-table.reports__table{
    .v-data-table__wrapper > table > thead > tr > th{
        padding: 0 0 0 16px;
    }
}
</style>
