<template>
    <v-dialog
        v-model="dialog"
        persistent
        transition="dialog-bottom-transition"
        width="90%"
        max-width="900px"
        overlay-color="black"
        content-class="rounded-xl elevation-0"
    >

    <v-card
            color="white"
            class="pa-9 d-flex flex-column align-items-center justify-content-center text-center"
        >
            <h1 class="my-3">
                {{
                     !isUpdate
                        ? $t('diagnoses.titles.assignEvaluation')
                        : $t('diagnoses.titles.updateDiagnosis')
                }}
            </h1>

            <!-- ------------ -->
            <!-- Date Pickers -->
            <!-- ------------ -->
            <v-row class="d-flex flex-column grey lighten-5 px-9 py-6 rounded-lg" >
                <v-row class="rounded-lg">
                    <!-- Start date -->
                    <v-col
                        data-cy="diagnostic-date-picker-start"
                        cols="12"
                        md="6"
                        class="d-flex flex-column text-start align-items-start justify-content-start"
                    >
                        <span class="body-2 grey--text font-weight-medium my-1">
                            {{ $t('assignments.inputs.dates.startDate') }}
                        </span>
                        <SingleDateInput
                            :currentValue="dateStart"
                            @date-picked="date => dateStart = date"
                            :placeholder="$t('assignments.placeholders.dates')"
                            :min="new Date().toISOString().substr(0, 10)"
                        />
                    </v-col>

                    <!-- Course -->
                    <v-col
                        data-cy="diagnostic-course-picker"
                        cols="12"
                        md="6"
                        class="d-flex flex-column text-start align-items-start justify-content-start"
                    >
                        <span class="body-2 grey--text font-weight-medium my-1">
                            {{ $t('assignments.inputs.course.pick') }}
                        </span>
                        <SimpleSelect
                            data-cy="diagnostic-course-picker-radio-btn"
                            defaultItemNameField="name"
                            :items="courses"
                            :model="currentCourse"
                            :value="currentCourse"
                            :placeholder="$t('assignments.placeholders.course')"
                            innerIcon="mdi-folder"
                            :disabled="!dateStart ? true : false || isUpdate"
                            @new-value-selected="(id) => (currentCourse = id)"
                        />
                    </v-col>
                </v-row>

                <!-- ------------- -->
                <!-- Course Picker -->
                <!-- ------------- -->
                <v-row class="rounded-lg my-2">
                    <!-- ------------------------ -->
                    <!-- Select a Evaluation Type -->
                    <!-- ------------------------ -->
                    <v-col
                        data-cy="diagnostic-evaluation-type-picker"
                        cols="12"
                        md="6"
                        class="d-flex flex-column text-start align-items-start justify-content-start"
                    >
                        <span class="body-2 grey--text font-weight-medium my-1">
                           {{ $t('diagnoses.inputs.placeholders.chooseEvaluationType') }}
                        </span>
                        <SimpleSelect
                            data-cy="=evaluation-type-picker"
                            defaultItemNameField="name"
                            :items="evaluationTypes"
                            :model="currentEvaluationType"
                            :value="currentEvaluationType"
                            :placeholder="$t('diagnoses.inputs.placeholders.chooseEvaluationType')"
                            innerIcon="mdi-note"
                            :disabled="!dateStart || !currentCourse ? true : false || isUpdate"
                            @new-value-selected="(id) => (currentEvaluationType = id)"
                        />
                    </v-col>
                </v-row>
            </v-row>

            <!-- Action buttons -->
            <v-row class="my-3" align-content="center" justify="center">
                <v-btn
                    elevation="0"
                    class="mx-3"
                    large
                    outlined
                    color="primary"
                    @click="closeModal"
                    :disabled="loadingData"
                >
                    <span class="text-center text-capitalize body-1 font-weight-medium">
                        {{ $t('assignments.actions.cancel') }}
                    </span>
                </v-btn>

                <v-btn
                    data-cy="diagnostic-create-evaluation-btn"
                    elevation="0"
                    class="mx-3"
                    large
                    color="primary"
                    @click="assignEvaluation"
                    :loading="loadingData"
                    :disabled="!isAbleToCreateAssignation"
                >
                    <v-icon small class="mr-2">mdi-clipboard-check-multiple</v-icon>
                    <span class="text-center text-capitalize body-1 font-weight-medium">
                        {{
                             !isUpdate
                                ? $t('diagnoses.actions.evaluation.activate')
                                : $t('diagnoses.actions.evaluation.update')
                        }}
                    </span>
                </v-btn>
            </v-row>
        </v-card>

    </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import SimpleSelect from '@/components/core/forms/selects/simpleSelect/index.vue'
import SingleDateInput from '@/components/core/forms/dates/singleDate/index.vue'
import { diagnosticAssignmentService } from '@/services/evaluations/diagnosticAssignment'
import * as Sentry from '@sentry/vue'

export default {
    name: 'AssignEvaluationModal',
    props: {
        dialog: {
            type: Boolean,
            default: false,
            required: true
        },
        isUpdate: {
            type: Boolean,
            default: false,
            required: false
        },
        oldEvaluation: {
            type: Object,
            default: null,
            required: false
        },
    },
    components: {
        SimpleSelect,
        SingleDateInput
    },
    data() {
        return {
            dateStart: null,
            endDate: null,
            currentCourse: null,
            currentEvaluationType: null,
            courses: [],
            loadingData: false,
        }
    },
    computed: {
        ...mapState({
            coursesFromTeacher: (state) => state.userData.courses,
            teacherId: (state) => state.userData.user.id,
        }),
        evaluationTypes() {
            return [
                {
                    id: 'INITIAL',
                    name: this.$t('diagnoses.diagnosis.types.initial')
                },
                {
                    id: 'INTERMEDIATE',
                    name: this.$t('diagnoses.diagnosis.types.middle')
                },
                {
                    id: 'ADVANCED',
                    name: this.$t('diagnoses.diagnosis.types.final')
                },
            ]
        },
        isAbleToCreateAssignation() {
            if (!this.dateStart || !this.currentCourse || !this.currentEvaluationType) {
                return false
            }

            return true
        },
    },
    methods: {
        closeModal(updateList) {
            this.resetData()
            this.$emit('close-modal', updateList)
        },
        async assignEvaluation() {
            this.loadingData = true
            try {
                await this.saveAssignment()
                this.closeModal(this.isUpdate)
            } catch (error) {
                Sentry.captureException(error)
                let message = this.$t(`diagnoses.errors.codes.${error}`)
                if (!message || !error) {
                    message = this.$t('diagnoses.errors.codes.default')
                }
                this.$modal.error(message)
            }
            this.loadingData = false
        },
        resetData() {
            this.dateStart = null
            this.currentCourse = 0
            this.currentEvaluationType = 0
        },
        saveAssignment() {
            return diagnosticAssignmentService.updateOrCreateAssignment({
                id: this.isUpdate ? this.oldEvaluation.id : null,
                dateStart: this.dateStart,
                course: {
                    id: this.currentCourse
                },
                level: this.currentEvaluationType,
                createdBy: {
                    id: this.teacherId,
                },
                evaluations: [],
            })
        },
        sortCourses() {
            this.courses = this.coursesFromTeacher
            .filter(course => course.grade !== 'G0')
            .sort((a, b) => {
                if (a.ordinalGrade !== b.ordinalGrade) {
                    return a.ordinalGrade - b.ordinalGrade
                }
                return a.section.localeCompare(b.section)
            })
        }
    },
    created() {
        // this.courses = this.coursesFromTeacher.filter(course => course.grade !== 'G0')
        this.sortCourses()
        this.resetData()
    },
    watch: {
        oldEvaluation(value) {
            this.resetData()
            if (this.isUpdate && value) {
                this.dateStart = value.dateStart.substr(0, 10)
                this.currentCourse = value.course.id
                this.currentEvaluationType = value.level
            }
        }
    },
}
</script>
