<template>
    <v-card class="d-inline-flex skill-chip" rounded="lg" flat color="deep-purple lighten-5 pl-5 py-5 ma-1">
       <v-row align-content="center" justify="center" class="ma-0 pa-0">
            <v-col class="ma-0 pa-0 d-flex flex-column align-start justify-center">
                <p class="subtitle-1 font-weight-bold ma-0 pa-0 mb-2 skill-chip__skill-name">
                    {{ skill }}
                </p>
                <strong class="display-1 font-weight-bold ma-0 pa-0">
                    {{ percentage }}%
                </strong>
            </v-col>
            <v-col class="ma-0 pa-0 d-flex flex-row align-center justify-start">
                <v-progress-circular
                    :rotate="-90"
                    width="18"
                    size="75"
                    :color="color"
                    :value="percentage"
                    class="d-inline-flex ma-0 pa-0"
                ></v-progress-circular>
            </v-col>
       </v-row>
    </v-card>
</template>

<script>
export default {
    name: 'SkillChip',
    props: {
        skill: {
            type: String,
            default: 'Skill'
        },
        percentage: {
            type: Number,
            default: 0
        },
        color: {
            type: String,
            default: 'primary'
        }
    },
    data() {
        return {
            skillData: [
                {
                    value: 90,
                    name: 'Skill'
                }
            ]
        }
    }
}
</script>

<style scoped>
.skill-chip__skill-name {
    line-height: 18px !important;
}
</style>