<template>
    <v-dialog
        v-model="dialog"
        persistent
        transition="dialog-bottom-transition"
        width="90%"
        max-width="900px"
        overlay-color="black"
        content-class="rounded-xl elevation-0"
    >
        <v-card
            color="light"
            class="pa-9 d-flex flex-column align-items-center justify-content-center text-center"
        >
            <h1 class="my-3">
                ¡Evaluación activada con éxito!
            </h1>

            <p class="body-1 p-6">
                Tus estudiantes verán la asignación el ingresar a su aplicación. ¡No olvides monitorear su progreso!
            </p>

            <!-- Action buttons -->
            <v-row class="my-3" align-content="center" justify="center">
                <v-btn
                    elevation="0"
                    class="mx-3"
                    large
                    color="deep-purple lighten-5"
                    @click="goBack"
                >
                    <span class="primary--text text-center text-capitalize body-1 font-weight-medium">
                        <v-icon color="primary" small>
                            mdi-arrow-left
                        </v-icon>
                        <span class="text-center text-capitalize body-1 font-weight-medium">
                            Volver a Evaluaciones
                        </span>
                    </span>
                </v-btn>

                <v-btn elevation="0" class="mx-3" large color="primary" @click="seeMoreEvaluation">
                    <v-icon small class="mr-2">mdi-clipboard-check-multiple</v-icon>
                    <span class="text-center text-capitalize body-1 font-weight-medium">
                        Ver evaluaciones asignadas
                    </span>
                </v-btn>
            </v-row>

        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'SuccessfulEvaluationAssigned',
    props: {
        dialog: { type: Boolean, default: true },
    },
    methods: {
        seeMoreEvaluation() {
            this.$emit('see-evaluations-assigned')
            this.dialog = false
        },
        goBack() {
            this.$emit('go-back')
        }
    },
}
</script>

<style scoped>
.class {
    color: #4a4866aa;
}
</style>
