<template>
    <v-container class="ma-0 pa-0">
        <v-btn
            color="grey lighten-1"
            outlined
            rounded
            elevation="0"
            small
            class="text-capitalize mb-6"
            @click="handleGoBackToAssignedEvaluations"
        >
            <v-icon class="mr-1" color="grey lighten-1" small>mdi-arrow-left</v-icon>
            <span class="grey--text font-weight-semibold">
                {{ $t('library.goBack') }}
            </span>
        </v-btn>
        <v-row
            class="d-flex flex-row flex-nowrap items-center justify-space-between w-100"
            align-content="center"
            justify="space-between"
        >
            <v-col class="ma-0 pa-0 pl-3">
                <v-card color="white" elevation="0" class="pa-3 py-5 d-inline-flex" rounded="lg">
                    <div class="d-inline-flex mr-3">
                        <SimpleSelect
                            :items="courseOptions"
                            :value="currentCourse"
                            :model="currentCourse"
                            :placeholder="$t('diagnoses.inputs.placeholders.chooseCourse')"
                            defaultItemValueField="id"
                            defaultItemNameField="name"
                            innerIcon="mdi-folder"
                            @new-value-selected="(id) => (currentCourse = id)"
                            withLabel
                            :label="$t('diagnoses.inputs.labels.course')"
                        />
                    </div>
                    <div class="mr-3">
                        <SimpleSelect
                            :items="evaluationTypeOptions"
                            :value="currentEvaluationType"
                            :model="currentEvaluationType"
                            :placeholder="$t('diagnoses.inputs.placeholders.chooseEvaluationType')"
                            defaultItemValueField="id"
                            defaultItemNameField="name"
                            innerIcon="mdi-layers"
                            @new-value-selected="(id) => (currentEvaluationType = id)"
                            withLabel
                            :label="$t('diagnoses.inputs.labels.evaluation')"
                        />
                    </div>
                </v-card>
            </v-col>
            <v-col class="ma-0 pa-0 d-flex align-center justify-end">
                <v-card elevation="0" rounded="lg" class="pa-3 py-5 d-inline-flex justify-center align-center">
                    <SimpleButton
                        icon="mdi-download"
                        :text="$t('diagnoses.actions.export')"
                        :loading="isDownloading"
                        @click="exportEvaluationResultExcel" />
                </v-card>
            </v-col>
        </v-row>

        <v-card class="w-100 py-6 my-5" rounded="lg" color="white" elevation="0">
            <h2 class="px-6 my-3">
                {{ $t('diagnoses.titles.generalInformation') }}
            </h2>
            <v-divider></v-divider>

            <section class="pa-6">
                <AssignedEvaluationsTables
                    :headers="assignedEvaluationsHeaders"
                    :items="assignedEvaluationsItems"
                    :loading="loading"
                    @on-update="onUpdateEvaluation"
                    @on-delete="onDeleteEvaluation"
                />
            </section>
        </v-card>

        <v-card class="w-100 py-6 my-5" rounded="lg" color="white" elevation="0">
            <h2 class="px-6 my-3">
                {{ $t('diagnoses.titles.classResultsPerSkill') }}
            </h2>
            <v-divider></v-divider>

            <v-row align-content="center" justify="space-around" class="pa-5">
                <SkillChip
                    :skill="$t('diagnoses.utils.skills.locate')"
                    :percentage="currentEvaluation ? getSkillsPercentages.locate : 0"
                    color="#828DF6"
                />
                <SkillChip
                    :skill="$t('diagnoses.utils.skills.integrate')"
                    :percentage="currentEvaluation ? getSkillsPercentages.integrate : 0"
                    color="#65C2FD"
                />
                <SkillChip
                    :skill="$t('diagnoses.utils.skills.reflect')"
                    :percentage="currentEvaluation ? getSkillsPercentages.reflect : 0"
                    color="#6DCDDD"
                />
                <SkillChip
                    :skill="$t('diagnoses.utils.skills.readingComprenhension')"
                    :percentage="currentEvaluation ? getSkillsPercentages.readingComprehension : 0"
                />
            </v-row>
        </v-card>

        <v-card class="w-100 py-6 my-5" rounded="lg" color="white" elevation="0">
            <h2 class="px-6 my-3">
                {{ $t('diagnoses.titles.studentsList') }}
            </h2>
            <v-divider></v-divider>

            <section class="pa-6">
                <EvaluationsResultsTable
                    :headers="assigendEvaluationsResultsHeaders"
                    :items="currentEvaluation ? generateTableItems : []"
                    :loading="loading"
                    :items-per-page="generateTableItems ? generateTableItems.length : 10"
                />
            </section>
        </v-card>

        <!-- Modal -->
        <UpdateEvaluationModal
            :dialog="updateEvaluation"
            :isUpdate="true"
            :oldEvaluation="evaluationToUpdate"
            @close-modal="closeUpdateModal"
        />
    </v-container>
</template>

<script>
import {
 mapMutations, mapState, mapGetters, mapActions
} from 'vuex'
import SimpleButton from '@/components/core/buttons/simpleButton/index.vue'
import SimpleSelect from '@/components/core/forms/selects/simpleSelect/index.vue'
import EvaluationsResultsTable from '@/components/diagnoses/shared/tables/evaluationsResults/index.vue'
import AssignedEvaluationsTables from '@/components/diagnoses/shared/tables/assignedEvaluations/index.vue'
import SkillChip from '@/components/core/chips/skillChip/index.vue'
import UpdateEvaluationModal from '@/components/diagnoses/shared/modals/assignEvaluation.vue'
import SuccessUpdateModal from '@/components/diagnoses/shared/modals/successfulEvaluationAssigned.vue'

export default {
    name: 'EvaluationsResults',
    components: {
        SimpleButton,
        SimpleSelect,
        EvaluationsResultsTable,
        AssignedEvaluationsTables,
        SkillChip,
        UpdateEvaluationModal,
        SuccessUpdateModal,
    },
    data() {
        return {
            currentCourse: 0,
            courseOptions: [],
            currentEvaluationType: 0,
            evaluationID: null,
            loading: false,
            updateEvaluation: false,
            successUpdate: false,
            selectedEvaluation: undefined,
            isDownloading: false,
        }
    },
    computed: {
        ...mapState({
            courses: (state) => state.userData.courses,
            diagnosesFilters: (state) => state.diagnoses.filters,
            assignedEvaluations: (state) => state.diagnoses.assignedEvaluations,
            userID: (state) => state.userData.user.id,
        }),
        ...mapGetters({
            evaluationDetail: 'diagnoses/currentDiagnostic',
        }),
        assigendEvaluationsResultsHeaders() {
            return [
                {
                    text: this.$t('diagnoses.tables.studentsList.labels.student'),
                    align: 'start',
                    sortable: false,
                    value: 'name',
                },
                {
                    text: this.$t('diagnoses.tables.studentsList.labels.state'),
                    align: 'center',
                    sortable: true,
                    value: 'diagnosticState',
                },
                {
                    text: this.$t('diagnoses.tables.studentsList.labels.beeStage'),
                    align: 'center',
                    sortable: true,
                    value: 'beereadersStage',
                },
                {
                    text: this.$t('diagnoses.utils.skills.readingComprenhension'),
                    align: 'center',
                    sortable: true,
                    value: 'skills.readingComprehension',
                },
            ]
        },
        generateTableItems() {
            if (!this.currentEvaluation) return []
            return (
                this.evaluationDetail?.assignedStudents.map((ev) => ({
                    ...ev,
                    evaluationID: this.currentEvaluation.id || 0,
                    name: `${ev.firstname} ${ev.lastname}`,
                })) || []
            )
        },
        assignedEvaluationsHeaders() {
            return [
                {
                    text: this.$t('diagnoses.tables.assignedEvaluations.labels.evaluation'),
                    align: 'start',
                    sortable: false,
                    value: 'evaluationType',
                },
                {
                    text: this.$t('diagnoses.tables.assignedEvaluations.labels.state'),
                    align: 'center',
                    sortable: false,
                    value: 'evaluationProgress',
                    width: '150px',
                },
                {
                    text: this.$t('diagnoses.tables.assignedEvaluations.labels.course'),
                    align: 'center',
                    sortable: false,
                    value: 'course',
                },
                {
                    text: this.$t('diagnoses.tables.assignedEvaluations.labels.startDate'),
                    align: 'center',
                    sortable: false,
                    value: 'startDate',
                },
                {
                    text: this.$t('diagnoses.tables.assignedEvaluations.labels.assignmentType'),
                    align: 'center',
                    sortable: false,
                    value: 'assignmentType',
                },
                {
                    text: this.$t('diagnoses.tables.assignedEvaluations.labels.actions'),
                    align: 'center',
                    sortable: false,
                    value: 'id',
                },
            ]
        },
        assignedEvaluationsItems() {
            const result = [
                ...this.assignedEvaluations.filter(
                    (ev) => ev.course.id === this.currentCourse
                        && ev.level === this.currentEvaluationType
                ),
            ]

            return result.map((e) => ({
                ...e,
                evaluationProgress: e.progress,
                levelName: this.evaluationTypeOptions?.find((option) => option?.id === e?.level)
                    ?.name,
            }))
        },
        currentEvaluation() {
            return this.assignedEvaluationsItems[0] || null
        },
        evaluationTypeOptions() {
            return [
                {
                    id: 'INITIAL',
                    name: this.$t('diagnoses.inputs.filters.evaluationType.initial'),
                },
                {
                    id: 'INTERMEDIATE',
                    name: this.$t('diagnoses.inputs.filters.evaluationType.middle'),
                },
                {
                    id: 'ADVANCED',
                    name: this.$t('diagnoses.inputs.filters.evaluationType.final'),
                },
            ]
        },
        getSkillsPercentages() {
            return {
                locate: this.currentEvaluation ? this.evaluationDetail?.classSkillsPercentages?.locate || 0 : 0,
                integrate: this.currentEvaluation ? this.evaluationDetail?.classSkillsPercentages?.integrate || 0 : 0,
                reflect: this.currentEvaluation ? this.evaluationDetail?.classSkillsPercentages?.reflect || 0 : 0,
                readingComprehension:
                    this.currentEvaluation ? this.evaluationDetail?.classSkillsPercentages?.readingComprehension || 0 : 0,
            }
        },
        evaluationToUpdate() {
            return this.selectedEvaluation
        },
    },
    methods: {
        ...mapMutations('diagnoses', ['setCourseSelected', 'setDiagnosticEvaluationType', 'resetCurrentAssignedEvaluationDetail']),
        ...mapActions('diagnoses', ['getDiagnosisDetail', 'getAssignedEvaluations', 'generateExcel']),
        setSelectedFilters() {
            if (this.diagnosesFilters.currentCourseID) {
                this.currentCourse = this.diagnosesFilters.currentCourseID
            } else {
                this.currentCourse = this.courseOptions[0].id
            }

            if (this.diagnosesFilters.currentEvaluationType) {
                this.currentEvaluationType = this.diagnosesFilters.currentEvaluationType
            } else {
                this.currentEvaluationType = this.evaluationTypeOptions[0].id
            }
        },
        async getDiagnosis() {
            try {
                this.loading = true
                const currentEvaluation = this.assignedEvaluationsItems[0]

                if (this.$route.query.evaluationID && this.$route.query.evaluationID === currentEvaluation.id) {
                    return await this.getDiagnosisDetail({
                        diagnosisID: this.$route.query.evaluationID,
                    })
                }

                if (!currentEvaluation) return
                return await this.getDiagnosisDetail({ diagnosisID: currentEvaluation.id })
            } catch (error) {
                this.resetCurrentAssignedEvaluationDetail()
                this.$modal.error(error)
            } finally {
                this.loading = false
            }
        },
        async onUpdateEvaluation(evaluation) {
            this.selectedEvaluation = evaluation
            this.updateEvaluation = true
        },
        closeUpdateModal() {
            this.updateEvaluation = false
            this.selectedEvaluation = null
        },
        onDeleteEvaluation(evaluation) {
            console.log(evaluation, 'Use delete service')
        },
        handleGoBackToAssignedEvaluations() {
            this.$router.push('/teacher/diagnoses/assigned')
        },
        async exportEvaluationResultExcel() {
            try {
                if (this.$route.query.evaluationID) {
                    this.isDownloading = true
                    const selectedCourse = this.courses.find((course) => course.id === this.currentCourse)
                    const currentEvaluationId = this.assignedEvaluationsItems[0].id
                    const evaluationId = this.$route.query.evaluationID === currentEvaluationId ? this.$route.query.evaluationID : currentEvaluationId

                    const currentEvaluationType = this.diagnosesFilters.currentEvaluationType
                    const currentEvaluation = this.evaluationTypeOptions?.find((option) => option?.id === currentEvaluationType)
                    ?.name
                    const resp = await this.generateExcel(evaluationId)
                    const outputFilename = `${selectedCourse?.name ?? 'All'}_Reporte de ${currentEvaluation}.xlsx`

                    const url = URL.createObjectURL(new Blob([resp]))
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('download', outputFilename)
                    document.body.appendChild(link)
                    link.click()
                    this.isDownloading = false
                } else {
                  this.$modal.loadError()
                }
            } catch (error) {
                this.$modal.error(error)
                this.isDownloading = false
            }
        },
    },
    watch: {
        async currentCourse(courseID) {
            this.setCourseSelected({ courseID })
        },
        async currentEvaluationType(evaluationType) {
            this.setDiagnosticEvaluationType({ evaluationType })
        },
        async currentEvaluation(evaluation) {
            if (!evaluation) return
            await this.getDiagnosis()
        },
    },
    async beforeMount() {
        this.loading = true

        if (this.assignedEvaluations.length === 0) {
            await this.getAssignedEvaluations({ teacherID: this.userID })
        }

        this.courseOptions = this.courses
            .filter((course) => course.grade !== 'G0')
            .sort((a, b) => {
                if (a.ordinalGrade < b.ordinalGrade) return -1
                if (a.ordinalGrade > b.ordinalGrade) return 1

                    // grades are equal, compare by section
                    if (a.section < b.section) return -1
                    if (a.section > b.section) return 1
                    return 0
            })

        if (!this.$route.query.evaluationID) {
            this.setSelectedFilters()
            await this.getDiagnosis()
        } else {
            const currentEvaluation = this.assignedEvaluations.find(
                (ev) => ev.id === this.$route.query.evaluationID
            )
            this.currentCourse = currentEvaluation.course.id
            this.currentEvaluationType = currentEvaluation.level
            await this.getDiagnosis()
        }

        this.loading = false
    },
}
</script>
